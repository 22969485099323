<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Liste des formations</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-danger mr-1"  v-b-modal.search>
            <i class="icon-md fas fa-search"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="1">
            <select class="form-control" v-model="datatable.limitPage" @change="datatableInit">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </b-col>
        </b-row>
        <b-table responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
          <template #cell(nom)="data">
            <a :href="'/#/formation/detail/'+data.item.id" target="_blank">{{ data.value }}</a>
          </template>
          <template #cell(status_id)="data">
            <b-button :variant="parametrage.formationStatus.find(p => {return p.id == data.value}).color" size="sm">{{parametrage.formationStatus.find(p => {return p.id == data.value}).titre}}</b-button>
          </template>
          <template #cell(produit_type_id)="data">
            {{getType(data.value)}}
          </template>
          <template #cell(commentaire)="data">
            <VueCustomTooltip :label="data.value">{{getComment(data.value)}}</VueCustomTooltip>
          </template>
          <template #cell(logs)="data">
            <VueCustomTooltip :label="getLog(data.value)">{{getLog(data.value,true)}}</VueCustomTooltip>
          </template>
        </b-table>
        <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
      </div>
    </div>
    <b-modal id="search" title="Recherche" size="xl" ref="myModalDeleteRef">
      <div class="row">
        <div class="form-group col-md-4" v-for="(field,i) in fields.filter(f => f.searchable == true)" :key="i">
          <label>{{field.label}}</label>
          <input type="text" :placeholder="'Rechercher par '+field.label" :data-column="field.key" class="form-control">
        </div>
        <div class="form-group col-md-4">
          <label>Statut</label>
          <select class="form-control" data-column="status_id">
            <option value="">Tout</option>
            <option v-for="s in parametrage.formationStatus" :value="s.id" :key="s.id">{{s.titre}}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label>Utilisateur</label>
          <select class="form-control" data-column="user_id">
            <option value="">Tout</option>
            <option v-for="u in users" :value="u.id" :key="u.id">{{u.prenom}} {{u.nom}}</option>
          </select>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="searchInit">
            Recherche
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.myModalDeleteRef.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
export default {
  data(){
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable:true,
          searchable:true
        },
        {
          key: 'nom',
          label: 'Nom',
          sortable:false,
          searchable:true
        },
        {
          key: 'prenom',
          label: 'Prenom',
          sortable:false,
          searchable:true
        },
        {
          key: 'telephone',
          label: 'Telephone',
          sortable:false,
          searchable:true
        },
        {
          key: 'produit_type_id',
          label: 'Type formation',
          sortable:false,
          searchable:false
        },
        {
          key: 'status_id',
          label: 'Statut',
          sortable:false,
          searchable:false
        },
        {
          key: 'commentaire',
          label: 'Commentaire',
          sortable:false,
          searchable:false
        },
        {
          key: 'logs',
          label: 'Historique',
          sortable:false,
          searchable:false
        }
      ],
      datatable:{
        currentPage: 1,
        nextPage: 2,
        previousPage: 0,
        limitPage: 20,
        totalRow: 0,
        data: []
      },
      sort:{
        field: 'id',
        value: 'DESC'
      },
      searchQuery:null,
      parametrage:{
        role:[],
        status:[],
        formationStatus:[],
      },
      users:[]
    };
  },
  components: {
    VueCustomTooltip
  },
  watch: {
  },
  beforeMount() {
    let params = {
      modules: ["UserRole","FormationStatus","ProduitType"]
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.role = res.data['UserRole'];
        this.parametrage.formationStatus = res.data['FormationStatus'];
        this.parametrage.ProduitType = res.data['ProduitType'];
        this.parametrage.status = res.data['status'];
      }
    });
    this.$store.back.ajax('/lead/users', null, (res) => {
      this.users = res;
    });
  },
  methods: {
    datatableInit: function(){
      var the = this
      this.$store.back.ajax('/formation/liste?'+this.query,null,(res) => {
        the.datatable = res;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    searchInit(){
      this.datatable.currentPage = 1;
      let query = {};
      let inputs  = document.querySelectorAll('[data-column]');
      inputs.forEach(elt => {
        let value  = elt.value;
        let key = elt.dataset.column;
        query[key] = value
      });
      if(Object.entries(query).length > 0){
        this.searchQuery = encodeURI(JSON.stringify(query));
      }else{
        this.searchQuery = null;
      }
      this.$refs.myModalDeleteRef.hide();
      this.datatableInit();
    },
    getComment(str){
      var n = "";
      var size = 50;
      if(str.length < size){
        return str;
      }
      var start = str.length - size;
      for(var i = start; i < str.length; i++){
        n += str[i];
      }
      return n;
    },
    getLog(logs,raccourci = false){
      var res = "";
      logs = Object.entries(logs);
      if(logs.length > 0){
        var l = logs[logs.length - 1][1].body;
        if(raccourci === true){
          for(var i = 0; i < 50; i++){
            res += l[i];
          }
        }else{
          res = l
        }
      }
      return res;
    },
    getType(val){
      var type = this.parametrage.ProduitType.find(p => {return p.id == val});
      if(type === undefined){
        return "Aucun";
      }else{
        return type.titre
      }
    }
  },
  computed: {
    query: function(){
      var sort = this.sort.field === null ? null : encodeURI(JSON.stringify(this.sort));
      return 'page='+this.datatable.currentPage+'&limit='+this.datatable.limitPage+'&sort='+sort+'&search='+this.searchQuery;
    }
  },
  mounted() {
    this.datatableInit()
  }
};
</script>
